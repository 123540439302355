const PREFIX = 'engage-webshop/';

const getLocalStorage = () => {
  if (typeof window !== 'undefined') {
    return window.localStorage;
  }
  return {
    setItem: () => {},
    getItem: () => '',
    removeItem: () => {}
  };
};

const setItem = (key: string, value: any): void => {
  if (value) {
    return getLocalStorage().setItem(`${PREFIX}${key}`, JSON.stringify(value));
  }
  return getLocalStorage().setItem(`${PREFIX}${key}`, value);
};

const getItem = (key: string): any => {
  const nativeValue = getLocalStorage().getItem(`${PREFIX}${key}`);
  try {
    return JSON.parse(nativeValue);
  } catch (e) {
    return nativeValue;
  }
};

const removeItem = (key: string): void => {
  return getLocalStorage().removeItem(`${PREFIX}${key}`);
};

const getAllItemKeys = (): string[] => {
  return Object.keys(getLocalStorage());
};

const removeItemsWithPrefix = (prefix: string = PREFIX) => {
  const allKeys = getAllItemKeys();
  const matchedKeys = allKeys.filter((key) => key.startsWith(prefix));
  matchedKeys.forEach((key) => {
    getLocalStorage().removeItem(key);
  });
};

export default {
  setItem,
  getItem,
  removeItem,
  removeItemsWithPrefix
};
