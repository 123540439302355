import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { AuthSlice, AuthMode } from '../../interfaces/auth';

const INITIAL: AuthSlice = {
  authDialogVisible: false,
  authMode: AuthMode.Login,
  user: {
    id: '',
    fullName: '',
    email: '',
    title: '',
    dob: '',
    passNo: '',
    gender: '',
    phone: '',
    address: '',
    postCode: '',
    nationality: '',
    tag: ''
  }
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState: INITIAL,
  reducers: {
    showAuthDialog: (state) => {
      state.authDialogVisible = true;
    },
    hideAuthDialog: (state) => {
      state.authDialogVisible = false;
    },
    setAuthMode: (state, action) => {
      state.authMode = action.payload || AuthMode.Login;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    }
  }
});

// this is for dispatch
export const { showAuthDialog, hideAuthDialog, setAuthMode, setUser } = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;

export const selectAuthDialogVisible = (state: RootState) => state.authSlice.authDialogVisible;
export const selectAuthMode = (state: RootState) => state.authSlice.authMode;
export const selectUser = (state: RootState) => state.authSlice.user;
