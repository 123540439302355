import { SiteMetadata } from '../interfaces/commons';
import jsonSiteMetadata from './siteMetadata.json';
import faqsData from './faqsData.json';
import footerData from './footerData.json';
import bannerData from './bannerData.json';
import bannerHeroData from './bannerHeroData.json';
import termsData from './termsData.json';
import {
  FAQsPageDataByLang,
  FooterDataByLang,
  BannerCtaDataByLang,
  BannerHeroDataByLang,
  TermsDataByLang
} from '../interfaces/pageContext';

export const siteMetadata: SiteMetadata = jsonSiteMetadata;
export const faqsPageDataByLang: FAQsPageDataByLang = faqsData;
export const footerDataByLang: FooterDataByLang = footerData;
export const bannerCtaDataByLang: BannerCtaDataByLang = bannerData;
export const bannerHeroDataByLang: BannerHeroDataByLang = bannerHeroData;
export const termsDataByLang: TermsDataByLang = termsData;

export const SUPPORT_PHONE_NUMBER = siteMetadata.supportPhone;
export const SUPPORT_EMAIL = siteMetadata.supportEmail;
export const COUNTRY = siteMetadata.country;
export const CLIENT_CURRENCY = siteMetadata.currency;
