import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = [
  {
    id:'',
    text: 'No appointments',
    name: '',
    price: '',
    type: '',
    currency: '',
    description: '',
    packageType: '',
    packageTests: [],
    key: 0
  }
];

export const testTypesSlice = createSlice({
  name: 'testTypes',
  initialState,
  reducers: {
    setTestypes: (_, action) => {
      return action.payload;
    }
  }
});

export const { setTestypes } = testTypesSlice.actions;
export default testTypesSlice.reducer;
export const testServices = (state: RootState) => state.testTypesSlice;
