import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface BookingSlice {
  bookingDialogVisible: boolean;
}

const INITIAL: BookingSlice = {
  bookingDialogVisible: false
};

export const bookingSlice = createSlice({
  name: 'bookingSlice',
  initialState: INITIAL,
  reducers: {
    showBookingDialog: (state) => {
      state.bookingDialogVisible = true;
    },
    hideBookingDialog: (state) => {
      state.bookingDialogVisible = false;
    }
  }
});

// this is for dispatch
export const { showBookingDialog, hideBookingDialog } = bookingSlice.actions;

// this is for configureStore
export default bookingSlice.reducer;

export const selectBookingDialogVisible = (state: RootState) => state.bookingSlice.bookingDialogVisible;
