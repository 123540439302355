import { createIntl, createIntlCache } from '@formatjs/intl';
import { localStorage } from '../constants';
import localStorageService from './localStorageService';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../constants/languages';
import { IntlShape } from '@formatjs/intl/src/types';

import enMessage from '../langs/en.json';
import idMessage from '../langs/id.json';
import zhMessage from '../langs/zh.json';
import { IntlKeyId } from '../interfaces/commons';
import { PrimitiveType } from 'intl-messageformat';

const intlCache = createIntlCache();
const cacheCreateIntl: { [k: string]: IntlShape } = {};

export const getTranslationByLanguage = (lang: string): { [key: string]: string } => {
  switch (lang) {
    case 'en':
      return enMessage;
    case 'id':
      return idMessage;
    case 'zh':
      return zhMessage;
    default:
      return enMessage;
  }
};

export const t = (id: IntlKeyId | string, defaultMessage?: string, values: Record<string, PrimitiveType> = {}) => {
  if (!id) {
    return defaultMessage || '';
  }
  const selectedLang = getSelectedLanguage();
  let intl: IntlShape = null;
  if (cacheCreateIntl[selectedLang]) {
    intl = cacheCreateIntl[selectedLang];
  } else {
    intl = createIntl(
      {
        locale: selectedLang,
        defaultLocale: DEFAULT_LANGUAGE,
        messages: getTranslationByLanguage(selectedLang)
      },
      intlCache
    );
  }
  return intl.formatMessage({ id, defaultMessage }, values);
};

export const setSelectedLanguage = (lang: string): string => {
  let selectedLang = lang;
  if (!SUPPORTED_LANGUAGES.includes(selectedLang)) {
    selectedLang = DEFAULT_LANGUAGE;
  }
  localStorageService.setItem(localStorage.SELECTED_LANGUAGE, selectedLang);
  return selectedLang;
};

export const getSelectedLanguage = (): string => {
  let selectedLang = localStorageService.getItem(localStorage.SELECTED_LANGUAGE) || DEFAULT_LANGUAGE;
  if (!SUPPORTED_LANGUAGES.includes(selectedLang)) {
    selectedLang = DEFAULT_LANGUAGE;
    setSelectedLanguage(selectedLang);
  }
  return selectedLang;
};

export default {
  setSelectedLanguage,
  getSelectedLanguage,
  getTranslationByLanguage,
  t
};
