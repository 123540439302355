import layoutSlice from './layout/layoutSlice';
import authSlice from './auth/authSlice';
import bookingSlice from './booking/bookingSlice';
import appointmentDetailsSlice from './booking/appointmentDetails';
import clientSlice from './booking/clientDetails';
import testTypesSlice from './booking/testTypes';
import patientFormSlice from './questionnaire/patientForm.redux';
import recommendationsSlice from './questionnaire/recommendations.redux';

export default {
  layoutSlice,
  authSlice,
  bookingSlice,
  appointmentDetailsSlice,
  clientSlice,
  testTypesSlice,
  patientFormSlice,
  recommendationsSlice
};
