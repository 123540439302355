import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { RootState } from '../store';

const patientObject = {
  fullName: '',
  email: '',
  phone: '',
  passNo: '',
  title: '',
  gender: '',
  dob: '',
  nationality: '',
  isValid: false,
  tag: ''
};

const addressObject = {
  address: '',
  unitNumber: '',
  postCode: '',
  remark: '',
  isValid: false,
  useAddress: false
};

const referralObject={
  id:'',
  url:'',
  logo:'',
  appointmentIds: [],
  clientId:''
}

const initialState = {
  patientDetails: [patientObject],
  addressDetails: addressObject,
  billingAddress: addressObject,
  adultNumber: 1,
  childNumber: 0,
  clinic: '',
  referral: referralObject,
  timeSlotId: '',
  timeSlotDate: moment().tz('Asia/Singapore').add(24, 'hours').format('YYYY-MM-DD'),
  timeSlotTime: '',
  cart: {
    id: '',
    name: '',
    type: 'on-site',
    price: '',
    currency: '',
    extraFee: '',
    extraFeeDesc: '',
    packageType: '',
    packageTests: '',
    selectedAdditionalTests: [],
    skipPayment: false,
    isReferred: false,
    discount: 0,
    code: '',
    source: '',
    branchId: '',
    validity: '',
    startDate: '',
    endDate: '',
    duration: '',
    additionalFields: []
  },
  totalPayment: {
    totalAmount: 0.0,
    vatTotal: 0.0,
    subTotal: 0.0,
    additionalTestsTotal: 0.0,
    discount: 0,
    surcharge: 0,
    extrafees: 0
  }
};

export const appointmentDetailsSlice = createSlice({
  name: 'appointmentDetails',
  initialState,
  reducers: {
    setClinic: (state, action) => {
      return { ...state, clinic: action.payload };
    },
    setReferral: (state, action) => {
      return { ...state, referral: action.payload };
    },
    setPatientDetails: (state, action) => {
      return { ...state, patientDetails: action.payload };
    },
    updatePatientDetails: (state, action) => {
      const pDetails = [...state.patientDetails];
      pDetails[action.payload.index] = action.payload.data;
      return { ...state, patientDetails: pDetails };
    },
    updateAddressDetails: (state, action) => {
      return { ...state, addressDetails: action.payload };
    },
    updateBillingAddress: (state, action) => {
      return { ...state, billingAddress: action.payload };
    },
    updateAdultCount: (state, action: any) => {
      const adultCount = action.payload;
      // if (ls) return { ...state, adultNumber: adultCount };
      if (state.adultNumber === 1 && state.adultNumber > adultCount) return;
      const newPatDetails = [...state.patientDetails];

      if (state.adultNumber < adultCount) {
        newPatDetails.push(patientObject);
      } else {
        newPatDetails.splice(-1);
      }
      return {
        ...state,
        adultNumber: adultCount,
        patientDetails: newPatDetails
      };
    },
    updateChildCount: (state, action) => {
      const { ls, childCount } = action.payload;
      if (ls) return { ...state, adultNumber: childCount };
      if (state.childNumber === 0 && state.childNumber > childCount) return;
      const newPatDetails: any = [...state.patientDetails];
      if (state.childNumber < childCount) {
        newPatDetails.push(patientObject);
      } else {
        newPatDetails.splice(-1);
      }
      return {
        ...state,
        childNumber: childCount,
        patientDetails: newPatDetails
      };
    },
    setTimeSlotId: (state, action) => {
      return {
        ...state,
        timeSlotId: action.payload
      };
    },
    setSelectedDate: (state, action) => {
      return {
        ...state,
        timeSlotDate: action.payload
      };
    },
    setSelectedTime: (state, action) => {
      return {
        ...state,
        timeSlotTime: action.payload
      };
    },
    updateCart: (state, action) => {
      return { ...state, cart: action.payload };
    },
    updateTotalPayment: (state, action) => {
      return { ...state, totalPayment: action.payload };
    }
  }
});

export const {
  updatePatientDetails,
  updateAddressDetails,
  updateBillingAddress,
  updateAdultCount,
  updateChildCount,
  setClinic,
  setPatientDetails,
  setReferral,
  setTimeSlotId,
  setSelectedDate,
  setSelectedTime,
  updateCart,
  updateTotalPayment
} = appointmentDetailsSlice.actions;
export default appointmentDetailsSlice.reducer;
export const appointmentDetails = (state: RootState) => state.appointmentDetailsSlice;
