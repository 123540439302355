import { MainMenuItem } from '../interfaces/commons';
import {
  UilEstate,
  UilMedkit,
  UilCommentQuestion,
  UilCommentChartLine,
  UilUser,
  UilShoppingCart
} from '@iconscout/react-unicons';

export const ROUTES = Object.freeze({
  home: '/',
  services: '/h/services',
  faqs: '/h/faqs',
  contactUs: '/h/contact',
  terms: '/h/terms',
  privacy: '/h/privacy',
  cart: '/h/cart',
  profile: '/h/profile',
  appointmentDetails: '/checkout/appointment-details',
  registerDetails: '/checkout/register-details',
  review: '/checkout/review',
  payment: '/checkout/payment',
  paymentCompleted: '/checkout/completed',
  questionnaire: '/h/questionnaire'
});

export const MAIN_MENU_ITEMS: MainMenuItem[] = [
  {
    label: 'Home',
    labelKey: 'navigation.home',
    url: ROUTES.home,
    icon: UilEstate
  },
  {
    label: 'Services',
    labelKey: 'navigation.services',
    url: ROUTES.services,
    icon: UilMedkit
  },
  {
    label: 'FAQs',
    labelKey: 'navigation.faqs',
    url: ROUTES.faqs,
    icon: UilCommentQuestion
  },
  {
    label: 'Contact US',
    labelKey: 'navigation.contact_us',
    url: ROUTES.contactUs,
    icon: UilCommentChartLine
  },
  {
    label: 'User login',
    labelKey: 'navigation.user_login',
    url: ROUTES.profile,
    icon: UilUser,
    className: 'lg:hidden justify-end mt-auto'
  },
  {
    label: 'Bookings',
    labelKey: 'navigation.bookings',
    url: ROUTES.cart,
    icon: UilShoppingCart,
    className: 'lg:hidden'
  },
  {
    label: 'Let us recommend',
    labelKey: 'navigation.questionnaire_title',
    url: ROUTES.questionnaire,
    icon: UilCommentQuestion
  }
];
