import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { save, load } from 'redux-localstorage-simple';

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) => {
  return configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware()
        .concat(thunk)
        .concat(
          save({
            states: [
              'layoutSlice',
              'clientSlice',
              'appointmentDetailsSlice',
              'testTypesSlice',
              'patientFormSlice',
              'recommendationsSlice'
            ],
            namespace: 'med_booking'
          })
        );
      return middleware;
    },
    ...options
  });
};

export const store = createStore({
  preloadedState: {
    ...load({
      states: [
        'layoutSlice',
        'clientSlice',
        'appointmentDetailsSlice',
        'testTypesSlice',
        'patientFormSlice',
        'recommendationsSlice'
      ],
      namespace: 'med_booking',
      disableWarnings: true
    })
  }
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default () => store;
