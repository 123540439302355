import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any[] = [];

export const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {
    setRecommendations: (_, action) => {
      return action.payload;
    },
  },
});

export const { setRecommendations } = recommendationsSlice.actions;
export default recommendationsSlice.reducer;
export const recommendationDetails = (state: RootState) => state.recommendationsSlice;

