import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
  answers: {},
  form: {},
  showModal: false,
};

export const registrationDetailsSlice = createSlice({
  name: 'patientForm',
  initialState,
  reducers: {
    updateForm: (state, action) => {
      return { ...state, form: action.payload };
    },
    updateAnswers: (state, action) => {
      return { ...state, answers: action.payload };
    },
    updateShowPatientFormModal: (state, action) => {
      return { ...state, showModal: action.payload };
    },
  },
});

export const { updateForm, updateAnswers, updateShowPatientFormModal } =
  registrationDetailsSlice.actions;
export default registrationDetailsSlice.reducer;
export const patientFormDetails = (state: RootState) => state.patientFormSlice;