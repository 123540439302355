import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
  clientId: '',
  clientName: '',
  address: '',
  city: '',
  country: '',
  currency: '',
  domain: '',
  email: '',
  logo: '',
  termsAndConditions: '',
  phone: '',
  primaryColor: '',
  secondaryColor: '',
  tertiaryColor: '',
  reminder: {},
  supportEmail: '',
  supportPhone: '',
  patientConsent: '',
  timezone: '',
  zipCode: '',
  subClients: [],
  cutoffTime: '',
  taxId: '',
  titles: [],
  skipPayment: false,
  rescheduleLimit: {
    daysBeforeAppointment: '',
    time: ''
  },
  hasQuestionnaire: false
};

export const clientSlice = createSlice({
  name: 'clientDetails',
  initialState,
  reducers: {
    setClient: (_, action) => {
      return action.payload;
    }
  }
});

export const { setClient } = clientSlice.actions;
export default clientSlice.reducer;
export const clientDetails = (state: RootState) => state.clientSlice;
