import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../../constants/languages';
import translationService from '../../services/translationService';

interface LayoutState {
  loading: boolean;
  enabledSmallSidebar: boolean;
  selectedLanguage: string;
}

const LAYOUT_INITIAL: LayoutState = {
  loading: false,
  enabledSmallSidebar: false,
  selectedLanguage: DEFAULT_LANGUAGE
};

export const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState: LAYOUT_INITIAL,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state) => {
      state.loading = false;
    },
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
    setSelectedLanguage: (state, action) => {
      let selectedLanguage = action.payload || DEFAULT_LANGUAGE;
      if (!SUPPORTED_LANGUAGES.includes(selectedLanguage)) {
        selectedLanguage = SUPPORTED_LANGUAGES[0];
      }
      translationService.setSelectedLanguage(selectedLanguage);
      state.selectedLanguage = selectedLanguage;
    }
  }
});

// this is for dispatch
export const { showLoading, hideLoading, toggleLoading, setSelectedLanguage } = layoutSlice.actions;

// this is for configureStore
export default layoutSlice.reducer;

export const selectLoading = (state: RootState) => state.layoutSlice.loading;
export const selectSelectedLanguage = (state: RootState) => {
  let selectedLanguage = state.layoutSlice.selectedLanguage;
  if (!SUPPORTED_LANGUAGES.includes(selectedLanguage)) {
    selectedLanguage = SUPPORTED_LANGUAGES[0];
  }
  return selectedLanguage
};
