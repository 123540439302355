export enum AuthMode {
  Login = 'Login',
  SignUp = 'SignUp',
  ForgotPassword = 'ForgotPassword'
}

export interface AuthSlice {
  authDialogVisible: boolean;
  authMode: AuthMode;
  user?: IUser;
}

export interface IUser {
  id: string;
  fullName: string;
  email: string;
  title: string;
  dob: string;
  passNo: string;
  gender: string;
  phone: string;
  address: string;
  postCode: string;
  nationality: string;
  tag?: string;
}
